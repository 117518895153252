<template>
    <Layout>
        <h4>New Payment Link</h4>
        <br />
        <h6>
            Note : The payment link generated is valid till :
            {{ linkExpiryDate }}
        </h6>
        <br />
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="generatePaymentLink"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">Project*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="payment.project"
                            @change="fetchUnits(payment.project)"
                            :options="mapppedProjects"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.project.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.payment.project.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.project.required"
                                >Please choose a project</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" v-if="!isUnitFetched && payment.project">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            disabled
                            placeholder="Fetching units..."
                            id="username"
                        />
                    </div>
                </div>

                <div class="col-lg-4" v-if="isUnitFetched && payment.project">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <!-- <b-form-select
                            id="ownerfname"
                            v-model="payment.unit"
                            name="ownerfname"
                            :options="mappedUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.unit.$error,
                            }"
                        ></b-form-select> -->
                        <model-select
                            :options="mappedUnits"
                            v-model="payment.unit"
                            placeholder="Search Units"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.unit.$error,
                            }"
                        >
                        </model-select>
                        <!-- {{ payment.unit }} -->
                        <div
                            v-if="submitted && $v.payment.unit.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.unit.required"
                                >Please choose a unit</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">NOC*</label>
                        <b-form-select
                            id="ownerfname"
                            v-model="payment.noc_type"
                            @change="otherNoc(payment.noc_type)"
                            name="ownerfname"
                            :options="nocs"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.noc_type.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.payment.noc_type.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.noc_type.required"
                                >Please choose an noc</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="showOther">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="ownerfname">Description *</label>
                        <input
                            v-model="payment.noc_type"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.noc_type.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.payment.noc_type.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.noc_type.required"
                                >Enter a description</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">First Name*</label>
                        <input
                            v-model="payment.first_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.first_name.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.payment.first_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.first_name.required"
                                >First name required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">Last Name*</label>
                        <input
                            v-model="payment.last_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.project.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.payment.last_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.last_name.required"
                                >Last name required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">Email*</label>
                        <input
                            v-model="payment.email"
                            type="email"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.email.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.payment.email.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.email.required"
                                >Email required</span
                            >
                            <span v-if="!$v.payment.email.email"
                                >This value should be a valid email.</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">Phone*</label>
                        <input
                            v-model="payment.contact_number"
                            type="tel"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.payment.contact_number.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.payment.contact_number.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.contact_number.required"
                                >Phone number is required</span
                            >
                            <span v-if="!$v.payment.contact_number.numeric"
                                >Only numeric values allowed</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">Amount*</label>
                        <input
                            v-model="payment.amount"
                            type="number"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.payment.amount.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.payment.amount.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.payment.amount.required"
                                >Amount is required</span
                            >
                            <span v-if="!$v.payment.amount.numeric"
                                >Only numeric values allowed.</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <submitButton />
        </form>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import unitMixin from "../../../mixins/unitMixin";
import submitButton from "../../../components/submitButton.vue";
import { required, email, numeric } from "vuelidate/lib/validators";
import { BASE_URL, PAYMENT_LINK_EXPIRY_DAYS } from "../../common";
import axios from "axios";
import alertMixin from "../../../mixins/alertMixin";
import { ModelSelect } from "vue-search-select";
export default {
    mixins: [unitMixin, alertMixin],
    components: {
        Layout,
        submitButton,
        ModelSelect,
    },
    validations: {
        payment: {
            project: { required },
            unit: { required },
            noc_type: { required },
            first_name: { required },
            last_name: { required },
            email: { required, email },
            contact_number: { required, numeric },
            amount: { required, numeric },
        },
    },
    mounted() {
        if (
            localStorage.cs_user_role === "Administrator" ||
            localStorage.cs_user_role === "Archiving" ||
            localStorage.cs_user_role === "Customer Service" ||
            localStorage.cs_user_role === "Collection"
        ) {
            this.$store.dispatch("apidata/fetchProjects");
            this.fetchList();
            let today = new Date();
            let currentDate =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
            console.log("the current  date is", currentDate);
            let expEpochDate = today.setDate(
                today.getDate() + PAYMENT_LINK_EXPIRY_DAYS
            );
            let expDate = new Date(expEpochDate);
            let expiryDate =
                expDate.getFullYear() +
                "-" +
                (expDate.getMonth() + 1) +
                "-" +
                expDate.getDate();
            console.log("the expiry date is", expiryDate);
            this.linkExpiryDate = expiryDate;
            this.payment.expiry_date = expiryDate;
        } else {
            this.$router.push({
                path: "/",
            });
        }
    },
    data() {
        return {
            submitted: false,
            showOther: false,
            linkExpiryDate: "",
            payment: {
                project: "",
                unit: "",
                noc_type: null,
                first_name: "",
                last_name: "",
                email: "",
                contact_number: "",
                amount: "",
                created_by: "",
                expiry_date: "",
            },
            nocs: [
                { value: null, text: "Please select an option" },
                { value: "IMMIGRATION", text: "Immigration" },
                { value: "TITLE DEED", text: "Title Deed" },
                { value: "AMENDMENT", text: "Amendment" },
                { value: "INTERIM REGISTRATION", text: "Interim Registration" },
                { value: "NOC REVALIDATION", text: "Noc Revalidation" },
                { value: "BUILT DRAWING", text: "Built Drawing" },
                { value: "TRASH BIN", text: "Trash Bin" },
                {
                    value: "BARRIER REMOTE CONTROL",
                    text: "Barrier Remote Control",
                },
                {
                    value: "OTHER",
                    text: "Other",
                },
            ],
        };
    },
    computed: {
        userInformation() {
            return this.$store.getters["apidata/getUserInfo"];
        },
        projects() {
            return this.$store.getters["apidata/getProjects"];
        },
        mappedCountries() {
            return this.countries.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.name,
                };
            });
        },
        mapppedProjects() {
            return this.projects
                .filter((project) => {
                    return (
                        project.id == 4 ||
                        project.id == 7 ||
                        project.id == 6 ||
                        project.id == 5
                    );
                })
                .map((ele) => {
                    return {
                        value: ele.id,
                        text: ele.title,
                    };
                });
        },
        mappedUnits() {
            return this.units.map((ele) => {
                return {
                    value: ele.ref_no,
                    text: ele.ref_no,
                };
            });
        },
    },
    methods: {
        otherNoc(data) {
            if (data === "OTHER") {
                this.showOther = true;
                this.payment.noc_type = "";
            } else {
                this.showOther = false;
            }
            console.log("other data", data);
        },
        fetchList() {
            axios
                .get(
                    `${BASE_URL}payment-links-list?token=${
                        localStorage.cs_user_token
                    }&offset=${0}&limit=50`
                )
                .then((res) => {
                    console.log(res.data.response);
                });
        },
        generatePaymentLink() {
            this.submitted = true;
            this.$v.$touch();
            this.payment.created_by = this.userInformation.name;
            if (!this.$v.$error) {
                console.log(BASE_URL);
                console.log(this.payment);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    console.log(localStorage.cs_user_token);
                    console.log(loader);
                    axios
                        .post(
                            `${BASE_URL}generate-payment-link?token=${localStorage.cs_user_token}`,
                            this.payment
                        )
                        .then((res) => {
                            if (res.data.status_code == "200") {
                                console.log(res);
                                loader.hide();
                                this.successPopUp(
                                    "Payment Link Generated Successfully"
                                );
                                this.$router.push("/payment-links-list");
                            } else {
                                this.errorMsg("Something went wrong");
                                loader.hide();
                            }

                            this.resetFields();
                        })

                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                            this.errorMsg("Something went wrong");
                        });
                }
            }
        },
    },
};
</script>
<style scoped></style>
